module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"brynabobic.com","short_name":"brynabobic.com","start_url":"/","background_color":"#330036","theme_color":"#330036","display":"minimal-ui","icon":"static/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/_layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":false,"modulePath":"/opt/build/repo/src/cms.js"},
    }]
